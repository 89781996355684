<template>
  <div class="news-list-container">
    <div class="news-list-body bgc_fff">
      <header class="news-list-header flex flex_jcbetween flex_acenter">
        <span class="fontsize_14 color_222">全部消息</span>
        <div class="flex flex_acenter pointer" @click="handleAllRead">
          <i class="icon-all_read fontsize_20">
            <span class="path1"></span>
            <span class="path2"></span>
          </i>
          <span class="fontsize_12 color_6E6E6E margin_left5">全部已读</span>
        </div>
      </header>
      <main
        class="news-list-main"
        v-infinite-scroll="loadData"
        infinite-scroll-distance="100"
      >
        <div
          v-for="(citem, cind) in list"
          :key="'MsgList_' + cind"
          @click="tapMsg(citem, cind)"
          class="tabs-news-item flex pointer"
          :class="{ hasRead: citem.is_read == 1 }"
        >
          <div class="read-status">
            <i
              :class="{
                'icon-unread': citem.is_read == 0,
                'icon-markRead': citem.is_read == 1,
              }"
            ></i>
          </div>
          <div class="tabs-news-body">
            <p class="fontsize_14 color_222 pover title">{{ citem.title }}</p>
            <p class="pover2 fontsize_12 color_6E6E6E content" v-html="citem.content"></p>
            <p class="fontsize_12 color_A1A1A1 time">{{ citem.created_at }}</p>
          </div>
        </div>
        <div class="nodata" v-if="total == 0">
          <img src="@/assets/nodata/couse_nodata.svg" alt="" class="statu_img">
          <span class="margin_top16 fontsize_12 color_A1A1A1">暂无消息</span>
        </div>
      </main>
    </div>
    <fd-dialog
      :title="msgDialogSet.title"
      :visible.sync="msgDialogSet.visible"
      width="788px"
      :before-close="(arg) => beforeCloseDialog(arg, 'msg')"
      custom-class="course-dialog not-full msg-dialog"
    >
      <div class="msg-detail">
        <p class="msg-detail-title fontsize_16 color_222 margin_bot8">
          {{ msgDialogSet.item.title }}
        </p>
        <p class="msg-detail-time fontsize_12 color_A1A1A1 margin_bot16">
          {{ msgDialogSet.item.created_at }}
        </p>
        <p
          class="msg-detail-content fontsize_14 color_6E6E6E"
          v-html="msgDialogSet.item.content"
        ></p>
      </div>
    </fd-dialog>
  </div>
</template>

<script>
import {
  getMessageList,
  getMeassageDetail,
  setMessageRead,
} from "@/libs/api/message";
export default {
  data() {
    return {
      page: 1,
      total: -2,
      limit: 20,
      list: [],
      msgDialogSet: {
        title: "消息详情",
        visible: false,
        item: {},
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    loadData() {
      // 加载下一页
      if (this.page * this.limit >= this.total) return;
      this.page++;
      this.getList();
    },
    getList() {
      getMessageList({ type: "", is_page: 1, limit: 20, page: this.page }).then(
        (res) => {
          if (res.errorcode == 0) {
            this.list = this.page == 1 ? res.data || [] : this.list.concat(res.data);
            this.total = res.total;
          }
        }
      );
    },
    beforeCloseDialog(done) {
      done();
    },
    tapMsg(item) {
      if (item.is_read==1) {
        this.msgDialogSet.item = JSON.parse(JSON.stringify(item));
        this.msgDialogSet.visible = true;
      }else{
        getMessageDetail(item.id).then(res=>{
          if (res.errorcode==0) {
            this.msgDialogSet.item = JSON.parse(JSON.stringify(res.data));
            this.msgDialogSet.visible = true;
            this.getMsgList();
          }
        })
      }
    },
    handleAllRead(){
      if(!this.list.length) return
      setMessageRead()
      .then(res=>{
        if (res.errorcode ==0) {
          this.$message.success('操作成功');
          this.page = 1;
          this.getList();
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.news-list-container {
  box-sizing: border-box;
  padding: 16px 0;
  height: 100%;
}
.news-list-body {
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 4px;
  padding: 0 24px;
  width: 58%;
  height: 100%;
  max-width: 836px;
  max-height: 800px;
}
.news-list-header {
  height: 68px;
  border-bottom: 1px solid #d4d4d4;
}
.news-list-main {
  height: calc(100% - 69px);
  overflow: hidden auto;
  box-sizing: border-box;
  padding-top: 8px;
  padding-right: 1px; //与滚动条的间距
  margin-right: -6px; //滚动条的右移动
}

.tabs-news-item {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  width: 100%;
  height: 122px;
  &:hover {
    background: #ededed;
  }
  &.hasRead {
    .tabs-news-body > p {
      color: #a1a1a1;
    }
  }
}
.read-status {
  padding-right: 8px;
}
.tabs-news-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content {
    height: 36px;
    line-height: 18px;
  }
  .title {
    line-height: 20px;
  }
  .time {
    line-height: 18px;
  }
}

// 消息详情
.msg-detail {
  box-sizing: border-box;
  overflow: hidden auto;
  height: 531px;
  padding: 22px 28px 20px 32px;
  .msg-detail-content {
    line-height: 40px;
    /deep/img {
      width: 100%;
      object-fit: contain;
    }
  }
  .msg-detail-title {
    line-height: 20px;
  }
  .msg-detail-time {
    line-height: 18px;
  }
}
</style>